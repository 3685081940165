import HamburgerMenu from './HamburgerMenu';
import Dropdown from './Dropdown';

function updateNavigation() {
    if (window.innerWidth > 900) {
        hamburgerMenu!.updateAria(true);
        document
            .querySelector('.site-header')!
            .querySelectorAll('.has-children')
            .forEach((element) => dropdowns.push(new Dropdown(element)));
    } else {
        hamburgerMenu!.updateAria(hamburgerMenu!.isMenuOpen());
        dropdowns.forEach(dropdown => dropdown.destroy())
        dropdowns = []
    }
}

let dropdowns: Dropdown[] = []
let hamburgerMenu:null|HamburgerMenu = null;

const navigation = document.querySelector('.site-header__container-main');

if (navigation) {
    hamburgerMenu = new HamburgerMenu(navigation);
}

let timer;

window.addEventListener(
    'resize',
    (event) => {
        clearTimeout(timer);

        timer = setTimeout(updateNavigation, 250);
    },
)

updateNavigation();
