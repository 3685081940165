import {PrivacyService} from "./privacy-service";

class PrivacyBanner extends HTMLElement
{
    connectedCallback() {
        if (this.hasAttribute('do-not-track') === true) {
            this.optOutAllNoChoiceMade();

            return;
        }

        this.setVisibility();

        this.addEventListener('enable', () => this.setVisibility())
        this.addEventListener('disable', () => this.setVisibility())

        this.querySelector('button.optIn')!
            .addEventListener('click', () => {
                this.optInAll().then(() => this.hide())
            })

        this.querySelector('button.optOut')!
            .addEventListener('click', () => {
                this.optOutAll().then(() => this.hide())
            })
    }

    protected choicesPending(): boolean {
        return Array
            .from<PrivacyService>(this.querySelectorAll('privacy-service'))
            .find((serviceEl: PrivacyService): boolean => serviceEl.noChoiceMade())
            !== undefined
    }

    public setVisibility(): void {
        this.hide();
    }

    public optInAll(): Promise<void[]> {
        const promises: Promise<void>[] = [];

        this.querySelectorAll('privacy-service')
            .forEach((serviceEl): void => {
                promises.push(this.optIn(serviceEl.getAttribute('key')!));
            })

        return Promise.all(promises);
    }

    public optOutAllNoChoiceMade(): Promise<void[]> {
        const promises: Promise<void>[] = [];

        Array.from(this.querySelectorAll<PrivacyService>('privacy-service'))
            .filter((serviceEl): boolean => serviceEl.noChoiceMade())
            .forEach((serviceEl): void => {
                promises.push(this.optOut(serviceEl.getAttribute('key')!));
            })

        return Promise.all(promises);
    }

    public optOutAll(): Promise<void[]> {
        const promises: Promise<void>[] = [];

        this.querySelectorAll('privacy-service')
            .forEach((serviceEl): void => {
                promises.push(this.optOut(serviceEl.getAttribute('key')!));
            })

        return Promise.all(promises);
    }

    public optIn(serviceName: string): Promise<void> {
        const serviceEl = this.querySelector<PrivacyService>(`privacy-service[key='${serviceName}']`);

        if (serviceEl === null) {
            throw new Error(`Unknown service: ${serviceName}`);
        }

        return serviceEl.optIn();
    }

    public optOut(serviceName: string): Promise<void> {
        const serviceEl = this.querySelector<PrivacyService>(`privacy-service[key='${serviceName}']`);

        if (serviceEl === null) {
            throw new Error(`Unknown service: ${serviceName}`);
        }

        return serviceEl.optOut();

    }

    public optionsUpdated(): void {

    }

    public show(): void {
        this.removeAttribute('hidden');
    }

    public hide(): void {
        this.setAttribute('hidden', 'true');
    }
}

customElements.define('privacy-banner', PrivacyBanner);
