import {PrivacyService} from "./privacy-service";

export class PrivacyOverlay extends HTMLElement
{
    private parentEl: Element | null | undefined;

    connectedCallback() {
        const acceptButtonEl = this.querySelector('button')!;
        const serviceKey = this.getAttribute('servicekey')!;
        this.addEventListener('click', e => e.stopPropagation());

        const privacyServiceEl = document.querySelector<PrivacyService>(`privacy-service[key="${serviceKey}"]`);
        this.parentEl = this.closest('.has-privacy-overlay');

        if (privacyServiceEl === null || privacyServiceEl.isEnabled() === true) {
            this.hide();

            return;
        }

        this.show();

        acceptButtonEl.addEventListener('click', (e) => {
            this.dispatchEvent(new CustomEvent('enable', {bubbles: true}));
            privacyServiceEl.optIn();
        })

        privacyServiceEl.addEventListener('enable', () => {
            this.hide();
        })
    }

    public show(): void {
        this.removeAttribute('hidden');
        this.parentEl?.querySelectorAll(
            'button:not(.privacy-overlay__dismiss), [href]:not(.privacy-overlay__link), input, select, textarea'
        ).forEach(
            (element) => {
                element.setAttribute('tabindex', '-1');
            },
        )
    }

    public hide(): void {
        this.setAttribute('hidden', 'true');
        this.parentEl?.querySelectorAll('[tabindex="-1"]').forEach(
            (element) => {
                element.removeAttribute('tabindex');
            }
        );
        this.parentEl?.classList.remove('has-privacy-overlay');
    }
}

customElements.define('privacy-overlay', PrivacyOverlay);
