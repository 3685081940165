import {getCookie, setCookie} from "typescript-cookie";
import {cookiePrefix, cookieOptions} from "./config";

export class PrivacyService extends HTMLElement
{
    protected cookieName: string = `${cookiePrefix}-${this.getAttribute('key')}`;

    connectedCallback() {
        if (getCookie(this.cookieName) === 'true') {
            this.enable();
        }
    }

    public optIn(): Promise<void> {
        return new Promise<void>(
            (resolve) => {
                setCookie(this.cookieName, 'true', cookieOptions);
                this.enable();

                resolve();
            }
        )
    }

    public optOut(): Promise<void> {
        return new Promise<void>(
            (resolve) => {
                setCookie(this.cookieName, 'false', cookieOptions);
                this.disable();

                resolve();
            }
        )
    }

    public noChoiceMade(): boolean {
        return typeof getCookie(this.cookieName) === 'undefined';
    }

    public enable(): void {
        this.querySelectorAll<PrivacyAwareScript>('privacy-aware-script')
            .forEach((privacyAwareScriptEl): void => privacyAwareScriptEl.enable());

        this.dispatchEvent(new CustomEvent('enable', {bubbles: true}));
    }

    public disable(): void {
        this.querySelectorAll<PrivacyAwareScript>('privacy-aware-script')
            .forEach((privacyAwareScriptEl): void => privacyAwareScriptEl.disable());

        this.dispatchEvent(new CustomEvent('disable', {bubbles: true}));
    }

    public isEnabled(): boolean {
        return this.isDisabled() === false;
    }

    public isDisabled(): boolean {
        return this.noChoiceMade() || getCookie(this.cookieName) !== 'true';
    }
}

customElements.define('privacy-service', PrivacyService);
