export default class BlockTabbedContent {
    private containerElement: HTMLElement;
    private tabButtonContainer: HTMLElement|null;
    private tabButtons: NodeListOf<HTMLElement>;
    private timerId?: number;
    private panels: NodeListOf<HTMLElement>;
    protected indexCurrentlyShown: number = 0;

    constructor(element: HTMLElement) {
        this.containerElement = element;
        this.tabButtonContainer = element.querySelector('.tabbed-content__tabs')!;
        this.tabButtons = this.tabButtonContainer.querySelectorAll('[id^="tab"]');
        this.panels = this.containerElement.querySelectorAll(
            '.tabbed-content__items__item'
        );

        this.containerElement.addEventListener(
            'click',
            (event) => {
                const target = event.target as HTMLElement|null;

                if (target?.closest('[id^="tab"]') === null) {
                    return;
                }

                event.preventDefault();

                clearTimeout(this.timerId);

                Array.from(this.tabButtons)
                    .forEach((tabButton): void => {
                        const index = Array.from(this.tabButtons).indexOf(tabButton);

                        if (tabButton.contains(target)) {
                            tabButton.focus();

                            this.showPanel(index);
                        } else {
                            this.hidePanel(index);
                        }
                    })
            }
        );
    }

    protected hidePanel(index: number): void {
        this.panels[index].classList.add('tabbed-content__items__item--hiding');
        this.tabButtons[index].removeAttribute('aria-selected');

        this.timerId = setTimeout(
            () => {
                Array.from(this.panels)
                    .forEach((panel) => {
                        if (panel.classList.contains('tabbed-content__items__item--hiding')) {
                            panel.classList.remove('tabbed-content__items__item--hiding');
                            panel.style.display = 'none';
                        }
                    });
            },
            750,
        );
    }

    public showPanel(index: number): void {
        this.panels[index].classList.remove('tabbed-content__items__item--hiding');
        this.panels[index].style.display = 'initial';
        this.tabButtons[index].setAttribute('aria-selected', 'true');

        this.indexCurrentlyShown = index;
    }

    public switchToTab(index: number): void {
        this.hidePanel(this.indexCurrentlyShown);
        this.showPanel(index);
    }

    public autoRotate(interval: number): void {
        setInterval(
            () => {
                let nextIndex = this.indexCurrentlyShown+1;

                if (nextIndex >= this.panels.length) {
                    nextIndex = 0;
                }

                this.switchToTab(nextIndex);
            },
            interval,
        );
    }
}
