import './styles.scss';

import BlockTabbedContent from "./tabbed-content";

document.querySelectorAll<HTMLElement>('.tabbed-content:not(.tabbed-content--single)')
    .forEach((element) => {
        const tabbedContent = new BlockTabbedContent(element);

        if ('autoRotate' in element.dataset && window.matchMedia('(min-width: 768px)').matches) {
            tabbedContent.autoRotate(parseInt(element.dataset.autoRotate!));
        }
    });
